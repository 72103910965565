import axios from "axios"
import api from "../api"

export const state = {
  results: {},
  isFetching: false,
  email: '',
  latestEvents: '',
  eventsBeforeEmailWasSent: '',
  step: 1,
  prompts: {
    prompt1: {},
    prompt2: {},
    prompt3: {},
    prompt4: {},
    prompt5: {},
    prompt6: ''
  },
  modal: {
    text: '',
      textarea: '',
      buttonText: ''
  },
  templates: {
    prompt2Modal: {
      text: `Simulate changes in user events between the 1st and 2nd email:`,
        textarea: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email`,
        buttonText: `Launch`,
    },
    prompt3ModalStep1: {
      text: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email.`,
        buttonText: `Next`,
    },
    prompt3ModalStep2: {
      text: `Here are the events defined for the second email. Edit them to simulate a change between the second and third email.`,
        buttonText: `Launch`,
    },
    prompt4Modal: {
      text: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email`,
        buttonText: `Launch`,
    },
    prompt5Modal: {
      text: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email`,
        buttonText: `Launch`,
    },
  },
  platformEvents: {}
}

export const mutations = {
  SET_PROMPTS_V3 (state, value) {
    state.prompts = value;
  },
  UPDATE_PROMPT_V3 (state, {step, part, value}) {
    part
      ? state.prompts[step][part] = value
      : state.prompts[step] = value
  }
}
export const actions = {
  async get_prompts_v3 ({commit}) {
    try {
      const { data } = await axios.get(`${api.personalized_emails.settings}/prompts-v3`);
      commit('SET_PROMPTS_V3', data);
    } catch (err) {
      console.log(err)
    }
  },
  async save_prompts_v3 ({state, commit}, key) {
    try {
      await axios.put(`${api.personalized_emails.settings}/prompts-v3`, {[key]: state.prompts[key]});
    } catch (err) {
      console.log(err)
    }
  }
}