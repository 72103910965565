import axios from "axios";
import api from "@/store/api";

export const state = {
  result: null,
  form: {
    url: "",
  },
  errorMsg: ''
}

export const mutations = {
  SET_YT_TRANSCRIPT_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_YT_TRANSCRIPT_FORM_FIELD (state, {name, value}) {
    state.form[name] = value
  },
}

export const actions = {
  async get_youtube_transcript_data({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'uploading')
      commit('SET_YT_TRANSCRIPT_STATE', {
        key: 'result', 
        value: null
      })
      commit('SET_YT_TRANSCRIPT_STATE', {
        key: 'errorMsg', 
        value: null
      })

      const socket = new WebSocket('wss://1rbv3hly34.execute-api.us-east-1.amazonaws.com/production')
      await new Promise((resolve, reject) => {
        socket.onopen = () => {
          console.log('WebSocket connection established')
          socket.send(JSON.stringify({ action: 'connect' }));
        }

        socket.onclose = () => {
          console.log('WebSocket connection closed')
        }
        
        socket.onmessage = async (event) => {          
          console.log({event})
          try {
            if(event.data.startsWith("FINISH@@")) {
              resolve()
              socket.close()
              return
            }

            if(event.data.startsWith("TRANSCRIPT@@")) {
              const text = event.data.replace("TRANSCRIPT@@", "")

              function cleanTranscript(text) {
                return text
                  .replace(/\d+\.\d+\|/g, '') // Remove timestamps
                  .split('|') // Split by '|'
                  .map(word => word.trim()) // Trim spaces
                  .join(' ') // Join words with space
                  .replace(/\s([.,!?])/g, '$1') // Fix spacing before punctuation
                  .replace(/\bi\b/g, 'I') // Capitalize "I"
                  .replace(/\d+\s\d+\s?/g, '') // Remove leftover timestamps
              }

              function formatTime(timestamp) {
                const totalSeconds = Math.floor(timestamp / 1000);
                const minutes = Math.floor(totalSeconds / 60);
                const seconds = totalSeconds % 60;
                return `${minutes}:${seconds.toString().padStart(2, '0')}`;
              }

              function extractTimestamps(input) {
                // Get all timestamps in the format
                const timestampRegex = /(\d+\.\d+)\|[^|]+\|(\d+\.\d+)/g;
                let match;
                let timestamps = [];
                
                while ((match = timestampRegex.exec(input)) !== null) {
                  timestamps.push({
                    start: parseFloat(match[1]),
                    end: parseFloat(match[2])
                  });
                }
                
                // If we have timestamps, return the very first and very last
                if (timestamps.length > 0) {
                  return {
                    startTime: timestamps[0].start,
                    endTime: timestamps[timestamps.length - 1].end
                  };
                }
                
                // Default values if no timestamps found
                return { startTime: 0, endTime: 0 };
              }

              const timestamps = extractTimestamps(text);

              const transcript = {
                startTime: formatTime(timestamps.startTime),
                text: cleanTranscript(text),
              };

              commit('SET_YT_TRANSCRIPT_STATE', {
                key: 'result', 
                value: {
                  text: (state.result?.text ?? "") + cleanTranscript(text),
                  raw: (state.result?.raw ?? "") + text, 
                  formatted: [...(state.result?.formatted ?? []), transcript]
                }
              })

              return
            }

            const data = JSON.parse(event.data)
            if (data && data.connectionId) {
              const youtubeUrl = encodeURIComponent(state.form.url)
              const connectionId = encodeURIComponent(data.connectionId)
              const response = await axios.get(`${api.youtube_transcript.download}?youtubeUrl=${youtubeUrl}&connectionId=${connectionId}&language=en`)
              commit('SET_REQ_STATUS', 'transcripting')
              console.log({response})
            }
          } catch (err) {
            reject(err)
            socket.close()
          }
        }
        
        socket.onerror = (error) => {
          reject(error || new Error('WebSocket connection failed'))
        }
      })
      
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      commit('SET_YT_TRANSCRIPT_STATE', {
        key: 'errorMsg', 
        value: err.response?.data || err.message || 'Failed to fetch transcript'
      })
    }
  }
}

function processTranscriptData(data) {
  // Check if this is a transcript data message
  if (!data || !data.startsWith('TRANSCRIPT@@')) {
    return null;
  }

  // Remove the TRANSCRIPT@@ prefix
  const content = data.replace('TRANSCRIPT@@', '');
  
  // Split the content by pipe character
  const parts = content.split('|');
  
  // Initialize variables to track sentences
  let sentences = [];
  let currentSentence = {
    startTime: null,
    endTime: null,
    text: [],
    formattedStartTime: '',
    formattedEndTime: ''
  };
  
  // Regular expressions for detecting sentence boundaries
  const sentenceEndRegex = /[.!?]$/;
  const newSentenceStartRegex = /^[A-Z]/;
  
  // Process triplets of data (timestamp, word, timestamp)
  for (let i = 0; i < parts.length - 2; i += 3) {
    if (i + 2 >= parts.length) break;
    
    const startTime = parseFloat(parts[i]);
    const word = parts[i + 1].trim();
    const endTime = parseFloat(parts[i + 2]);
    
    // Skip empty words
    if (!word) continue;
    
    // Start a new sentence if needed
    if (currentSentence.startTime === null) {
      currentSentence.startTime = startTime;
      currentSentence.formattedStartTime = formatTimestamp(startTime);
    }
    
    // Update the current sentence
    currentSentence.text.push(word);
    currentSentence.endTime = endTime;
    currentSentence.formattedEndTime = formatTimestamp(endTime);
    
    // Check if this word ends a sentence or if the next word starts a new sentence
    const isEndOfSentence = sentenceEndRegex.test(word);
    const nextWordIndex = i + 4; // Next word would be 3 positions ahead (timestamp, word, timestamp, then next word)
    const nextWordStartsNewSentence = nextWordIndex < parts.length && 
                                     parts[nextWordIndex] && 
                                     newSentenceStartRegex.test(parts[nextWordIndex].trim());
    
    // If this is the end of a sentence, finalize it and start a new one
    if (isEndOfSentence || nextWordStartsNewSentence) {
      // Only add sentences that have content
      if (currentSentence.text.length > 0) {
        sentences.push({
          startTime: currentSentence.startTime,
          endTime: currentSentence.endTime,
          text: cleanSentence(currentSentence.text.join(' ')),
          formattedStartTime: currentSentence.formattedStartTime,
          formattedEndTime: currentSentence.formattedEndTime
        });
      }
      
      // Reset for next sentence
      currentSentence = {
        startTime: null,
        endTime: null,
        text: [],
        formattedStartTime: '',
        formattedEndTime: ''
      };
    }
  }
  
  // Add any remaining content as a sentence
  if (currentSentence.text.length > 0) {
    sentences.push({
      startTime: currentSentence.startTime,
      endTime: currentSentence.endTime,
      text: cleanSentence(currentSentence.text.join(' ')),
      formattedStartTime: currentSentence.formattedStartTime,
      formattedEndTime: currentSentence.formattedEndTime
    });
  }
  
  return {
    sentences: sentences,
    fullText: sentences.map(s => s.text).join(' '),
    timestamps: sentences.map(s => ({
      time: s.formattedStartTime,
      text: s.text
    }))
  };
}

function formatTimestamp(timestamp) {
  const totalSeconds = Math.floor(timestamp / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

function cleanSentence(text) {
  return text
    .replace(/\s+/g, ' ')                // Fix multiple spaces
    .replace(/\s([.,!?])/g, '$1')        // Fix spacing before punctuation
    .replace(/\bi\b/g, 'I')              // Capitalize standalone "i"
    .replace(/^\s+|\s+$/g, '')           // Trim leading/trailing spaces
    .replace(/([.!?])\s*([a-z])/g, function(match, punctuation, letter) {
      return punctuation + ' ' + letter.toUpperCase();  // Capitalize after sentence ends
    });
}