<template>
  <div class="personalized-emails w100">
    <h1 class="bold">
      Personalized email V3
      <br>
      <span>
        Send personalized emails to RS users
      </span>
    </h1>

    <div class="prompts-group">
      <details class="pointer" v-for="(item, index) in buttons"
               :key="index">
        <summary>Prompt for {{ item.name }} email &nbsp;</summary>
        <textarea
            :value="prompts[item.key].a"
            @input="handlePrompt($event, {step: item.key, part: 'a'})"
            placeholder="job description"
        />
        <br>
        <textarea
            :value="prompts[item.key].b"
            @input="handlePrompt($event, {step: item.key, part: 'b'})"
            placeholder="job description"
        />
        <button class="purple-btn block"
                @click="handlePromptSave(item.key)">
          Save changes
        </button>
      </details>
    </div>

    <div class="results">
      <div v-for="(value, key) in results"
           :key="key"
           class="m-b-16">
        <h2>
          {{ key }}:
        </h2>
        <div class="m-t-8">
          <div class="bg-white rounded-lg shadow-lg p-6 font-mono text-sm">
            <div class="overflow-auto">
              <div class="ml-4">
                <JsonNode :value="value" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed, reactive, ref} from "vue";
  import BaseModal from "@/components/BaseModal.vue";
  import JsonNode from "@/components/JsonNode.vue";

  const {state, dispatch, commit} = useStore();
  dispatch('get_prompts_v3');

  const prompts = computed(() => state.personalizedEmailsV3.prompts);
  const results = computed(() => state.personalizedEmailsV3.results);
  const buttons = [
    {name: '1st', key: 'prompt1'},
    {name: '2nd', key: 'prompt2'},
    {name: '3rd', key: 'prompt3'},
    {name: '4th', key: 'prompt4'},
    {name: '5th', key: 'prompt5'},
  ];

  function handlePrompt (ev, { step, part }) {
    commit('UPDATE_PROMPT_V3', {step, part, value: ev.target.value});
  }

  function handlePromptSave (key) {
    dispatch('save_prompts_v3', key)
  }
</script>

<style lang="scss">
  .personalized-emails {
    @media only screen and (min-width: 0) {
      .email {
        width: 450px;
        margin-bottom: 30px;
      }
      .purple-btn {
        height: 48px;
        padding: 0 16px;
        margin-right: 30px;
        flex: 0 0 calc(33.33% - 16px);
        max-width: calc(33.33% - 16px);
      }
      .prompts-group {
        details {
          margin-top: 30px;
          summary {
            color: #ababab;
            font-size: 1.8rem;
            font-weight: 700;
          }
          textarea {
            width: 600px;
            height: 300px;
            margin-top: 30px;
          }
          &:first-child {
            textarea {
              width: 100%;
              height: 200px;
              margin-top: 30px;
            }
          }
          button {
            margin-top: 19px;
          }
        }
      }
      .results {
        width: 100%;
        //max-width: 68%;
        margin-top: 30px;
        h2 {
          font-weight: 400;
          color: #ababab;
          font-size: 1.8rem;
        }
      }
      .base-modal {
        > div {
          width: 424px;
          padding: 20px 30px;
        }
        p {
          margin-top: 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        textarea {
          width: 100%;
          height: 308px;
          margin-top: 30px;
          display: block;
          border-radius: 4px;
          border: 0.5px solid #000000;
        }
        button {
          width: 70px;
          height: 46px;
          margin: 30px auto 0;
          border: none;
          background-color: #7c7c7c;
          color: #fff;
          font-size: 1.4rem;
          font-weight: 700;
          border-radius: 4px;
        }
        .cancel-modal {
          background-color: transparent;
          margin-right: 16px;
          color: #7c7c7c;
        }
      }
    }
  }
</style>