<template>
  <div class="youtube-transcript-view">
    <h1 class="bold">
      Youtube Transcript <br>
      <span>Convert Youtube video to transcript</span>
    </h1>

    <h2>
      Insert video URL
    </h2>

    <form class="flex align-center" @submit.prevent="handleSubmit">
      <BaseInput ref="urlInput"
                 name="url"
                 placeholder="https://youtube.com"
                 :value="form.url"
                 :disabled="isProcessing"
                 @enter="handleSubmit"
                 @input="handleInput" />
      <div class="flex w100">
        <button class="purple-btn" 
                type="button" 
                @click="handleClear" 
                :disabled="submitDisabled">
                Clear
        </button>
        <button class="purple-btn" 
                :disabled="submitDisabled">
                Submit
        </button>
      </div>
    </form>

    <div v-if="isProcessing" class="loading-container">
      <BaseLoader />
      <p class="text-center m0" v-if="isUploading">Uploading the video</p>
      <p class="text-center m0" v-else-if="isTranscribing">Hang tight, we are processing your transcript now!</p>
    </div>

    <p v-else-if="errorMsg">
      {{ errorMsg }}
    </p>

    <div v-if="transcriptDataFormatted" class="transcript-container">
      <div class="actions">
        <button @click="toggleDisplay">
          Display as {{ currentDisplay === 'text' ? 'Bubbles' : 'Text' }}
        </button>
        <div class="copy-buttons">
          <button 
            class="copy-btn" 
            @click="copyTranscript('text')"
            :disabled="!transcriptDataText || copyStatus.text"
          >
            <template v-if="copyStatus.text">Copied!</template>
            <template v-else-if="copyStatus.error">Failed</template>
            <template v-else>Copy Text</template>
          </button>
          <button 
            class="copy-btn" 
            @click="copyTranscript('raw')"
            :disabled="!transcriptDataRaw || copyStatus.raw"
          >
            <template v-if="copyStatus.raw">Copied!</template>
            <template v-else-if="copyStatus.error">Failed</template>
            <template v-else>Copy Raw</template>
          </button>
          <button 
            class="copy-btn" 
            @click="copyTranscript('formatted')"
            :disabled="!transcriptDataFormatted || copyStatus.formatted"
          >
            <template v-if="copyStatus.formatted">Copied!</template>
            <template v-else-if="copyStatus.error">Failed</template>
            <template v-else>Copy Formatted</template>
          </button>
        </div>
      </div>

      
      <div class="transcript-messages">
        <template v-if="currentDisplay === 'bubbles'">
          <div  
            v-for="(message, index) in transcriptDataFormatted" 
            :key="index"
            class="message-container"
          >
            <div class="message-bubble">
              <div class="message-text">{{ message.text }}</div>
              <div class="message-meta">
                <span class="timestamp">{{ message.startTime }}</span>
              </div>
            </div>
          </div>
        </template>

        <p v-else>
          {{ transcriptDataText }}
        </p>
      </div>
    </div>
  </div>
</template>
  
<script setup>
  import { computed, onMounted, ref } from "vue";
  import { useStore } from "vuex";

  const { state, dispatch, commit } = useStore()

  const currentDisplay = ref("bubbles")
  const urlInput = ref(null)
  const copyStatus = ref({
    text: false,
    raw: false,
    formatted: false,
    failed: false
  })
  const reqStatus = computed(() => state.reqStatus)
  const isUploading = computed(() => reqStatus.value === 'uploading')
  const isTranscribing = computed(() => reqStatus.value === 'transcripting')
  const isProcessing = computed(() => isUploading.value || isTranscribing.value)
  const errorMsg = computed(() => state.youtubeTranscript.errorMsg)
  const form = computed(() => state.youtubeTranscript.form)
  const transcriptData = computed(() => state.youtubeTranscript.result)
  const transcriptDataRaw = computed(() => transcriptData.value?.raw)
  const transcriptDataText = computed(() => transcriptData.value?.text)
  const transcriptDataFormatted = computed(() => transcriptData.value?.formatted)
  const submitDisabled = computed(() => isProcessing.value || (!form.value.url))

  onMounted(() => {
    urlInput.value.focus()
  })

  function toggleDisplay() {
    currentDisplay.value = currentDisplay.value === "text" ? "bubbles" : "text";
  }

  function handleInput ({name, value}) {
    commit('SET_YT_TRANSCRIPT_FORM_FIELD', {name, value})
  }

  function handleClear () {
    commit('SET_YT_TRANSCRIPT_FORM_FIELD', {name: "url", value: null})
    commit('SET_YT_TRANSCRIPT_STATE', {
      key: 'result', 
      value: null
    })
    urlInput.value.focus()
  }

  function handleSubmit () {
    dispatch("get_youtube_transcript_data")
  }
  
  function copyTranscript(type) {
    let textToCopy = '';

    if (type === 'text' && transcriptDataText.value) {
      textToCopy = JSON.stringify(transcriptDataText.value);
    }
    
    if (type === 'raw' && transcriptDataRaw.value) {
      textToCopy = JSON.stringify(transcriptDataRaw.value);
    }

    if (type === 'formatted' && transcriptDataFormatted.value) {
      textToCopy = transcriptDataFormatted.value.map(item => 
        `[${item.startTime}] ${item.text}`
      ).join('\n');
    }

    if(!textToCopy) return;
    
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        copyStatus.value[type] = true;
        setTimeout(() => {
          copyStatus.value[type] = false;
        }, 1000);
      })
      .catch(() => {
        copyStatus.value.error = true;
        setTimeout(() => {
          copyStatus.value.error = false;
        }, 1000);
      });
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .youtube-transcript-view {
    @media only screen and (min-width: 0) {
      max-width: 784px;
      margin: 0 auto 200px;

      h2 {
        color: $tech-gray;
        font-weight: 700;
        margin: 16px 0;
      }

      form {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 48px;

        .base-input.url, button {
          width: 100%
        }

        > div {
          gap: 16px;
        }
      }

      .loading-container {
        margin-bottom: 48px;
      }

      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 8px;

        > button {
          width: 100%;
        }

        button {
          padding: 8px 16px;
          background-color: #f0f2f5;
          border: 1px solid #ddd;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          transition: all 0.2s ease;
          
          &:hover:not(:disabled) {
            background-color: #e6e8ea;
          }
          
          &:disabled {
            opacity: 0.75;
            cursor: initial;
          }
        }

        .copy-buttons {
          display: flex;
          justify-content: center;
          gap: 16px;
          margin-bottom: 16px;

          button {
            width: 176px;
          }
        }
      }

      .transcript {
        &-container {
          max-width: 600px;
          margin: 0 auto;
          padding: 20px;
          background-color: #f8f9fa;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        
        &-messages {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
        
        &-empty {
          text-align: center;
          padding: 40px;
          color: #666;
          font-style: italic;
        }
      }

      .message {
        &-container {
          margin-bottom: 12px;
        }
        
        &-bubble {
          width: 100%;
          padding: 12px 16px;
          background-color: #f0f2f5;
          border-radius: 8px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        }
        
        &-text {
          margin-bottom: 4px;
          word-wrap: break-word;
        }
        
        &-meta {
          display: flex;
          justify-content: flex-end;
          gap: 8px;
          font-size: 0.75rem;
          color: #666;
        }
      }
    }
  }
</style>