import axios from "axios"
import api from "../api"

export const state = {
  results: {},
  isFetching: false,
    email: '',
    latestEvents: '',
    eventsBeforeEmailWasSent: '',
    step: 1,
    prompts: {
    prompt1: '',
      prompt2: '',
      prompt3: '',
      prompt4: '',
      prompt5: '',
      prompt6: ''
  },
  modal: {
    text: '',
      textarea: '',
      buttonText: ''
  },
  templates: {
    prompt2Modal: {
      text: `Simulate changes in user events between the 1st and 2nd email:`,
        textarea: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email`,
        buttonText: `Launch`,
    },
    prompt3ModalStep1: {
      text: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email.`,
        buttonText: `Next`,
    },
    prompt3ModalStep2: {
      text: `Here are the events defined for the second email. Edit them to simulate a change between the second and third email.`,
        buttonText: `Launch`,
    },
    prompt4Modal: {
      text: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email`,
        buttonText: `Launch`,
    },
    prompt5Modal: {
      text: `Here are the events we used for the first email. Edit them to simulate a change between the first and second email`,
        buttonText: `Launch`,
    },
  },
  platformEvents: {}
}

export const mutations = {
  SET_PE_STATE (state, {key, data}) {
    state[key] = data;
  },
  SET_RESULTS (state, data) {
    const desiredOrder = ['emailGenerated', 'enrichedData', 'apolloData', 'hubspotData', 'initialPrompt'];
    const sortedData = desiredOrder.reduce((acc, key) => {
      if (data.hasOwnProperty(key)) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    state.results = {
      ...state.results,
      ...sortedData
    };
  },
  SET_PROMPTS (state, value) {
    console.log(`SET_PROMPTS v1`);
    state.prompts = value;
  },
  UPDATE_PROMPT (state, {key, value}) {
    state.prompts[key] = value;
  },
  UPDATE_MODAL_TEXTAREA (state, value) {
    if (state.step === 3 && state.modal.buttonText === 'Launch') {
      state.eventsBeforeEmailWasSent = value;
      return
    }
    state.latestEvents = value;
  },
  SET_MODAL_TEXT (state, value) {
    state.modal = state.templates[value];
  },
  SET_PLATFORM_EVENTS (state, value) {
    state.platformEvents = value;
  }
}
export const actions = {
  async get_prompts ({commit}) {
    try {
      const { data } = await axios.get(`${api.personalized_emails.settings}/prompts-prod`);
      commit('SET_PROMPTS', data);
    } catch (err) {
      console.log(err)
    }
  },
  async save_prompts ({state, commit}, key) {
    try {
      await axios.put(`${api.personalized_emails.settings}/prompts-prod`, {[key]: state.prompts[key]});
    } catch (err) {
      console.log(err)
    }
  },
}